    // Start of Selection
    import React, { useEffect } from 'react';
    import './About.css'; // Optional: Create this file for About page-specific styles

    const About = () => {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
    
        return (
            <div className="about-container">
                <header className="about-header">
                    <h1>
                        <span className="about-title">About StageDive</span>
                        <span className="about-subtitle">Philly</span>
                    </h1>
                </header>
                <div className="about-content">
                    <section className="about-section">
                        <h2>Our Mission</h2>
                        <p>StageDive Philly is dedicated to enhancing the music fan experience in Philadelphia. We provide an easy-to-use platform for discovering, tracking, and saving local music events.</p> 
                        <p>Our focus is on connecting music lovers with the vibrant local scene, not on promotion. We aim to be your Go-To platform for Philly's thriving music community, helping you never miss a beat.</p>
                    </section>
                    {/* <section className="about-section">
                        <h2>What We Do</h2>
                        <p>We curate a comprehensive list of music events happening in Philadelphia, making it easy for you to discover new artists, venues, and experiences. From intimate gigs to major concerts, we've got you covered.</p>
                    </section> */}
                    <section className="about-section">
                        <h2>Get Involved</h2>
                        <p>Music Fan? Create an Account! Music fans can save their favorite events and stay updated on the latest happenings in the Philly music scene.</p>
                        <p>Are you an artist or venue owner? Create an Account and Submit your events to be featured on our platform.</p>
                    </section>
                    <section className="about-section">
                        <h2>Who We Are</h2>
                        <p>StageDive Philly was created by a husband and wife team who are passionate about music and the Philadelphia scene.</p>
                        <p> We built this platform as a tool for ourselves, to help us track shows we wanted to attend when we could get a night off together and find a babysitter. Our personal need for an easy way to discover and remember great local events evolved into this service, which we're excited to share with the entire Philly music community.</p>
                    </section>
                    <section className="about-section">
                        <h2>What's Next</h2>
                        <p>StageDive Philly is part of the StageDive.io project, which aims to improve how people discover and enjoy local music scenes. We're starting in Philadelphia, but we hope to expand to other cities in the future.</p>
                        <p>Our goal is to create a platform where music fans can easily find events, connect with other fans, and support artists, both in their hometown and when traveling. As we grow, we'll keep focusing on what makes each local music scene special, while also making it easier for people to explore music in different places.</p>
                    </section>
                </div>
            </div>
        );
    };
    
    export default About;