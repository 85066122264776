import React, { useState, useEffect } from 'react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useSupabase } from './SupabaseProvider.tsx';
import { FaTimes } from 'react-icons/fa';
import './Login.css'; // Import the new CSS file

function Login({ onClose, onLogin }) {
  const supabase = useSupabase();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (supabase) {
      setLoading(false);
    } else {
      setError('Failed to initialize Supabase client');
    }
  }, [supabase]);

  const handleAuthChange = async (event, session) => {
    if (event === 'SIGNED_IN' && session) {
      onLogin(session.user);
      onClose();
    }
  };

  if (loading) {
    return <div className="login-loading-text">Initializing authentication...</div>;
  }

  if (error) {
    return <div className="login-loading-text">Error: {error}</div>;
  }

  return (
    <div className="login-overlay">
      <div className="login-container">
        <button className="login-close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <h2 className="login-title">User Login</h2>
        <Auth
          supabaseClient={supabase}
          appearance={{ 
            theme: ThemeSupa,
            style: {
              button: { width: '100%', backgroundColor: '#004C54', color: 'white' },
              anchor: { width: '100%' },
            },
          }}
          providers={[]} // Removed all social buttons
          onAuthStateChange={handleAuthChange}
        />
      </div>
    </div>
  );
}

export default Login;
