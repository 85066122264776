import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt, FaInfoCircle, FaLink, FaSearch, FaMapMarkerAlt, FaSpinner } from 'react-icons/fa';
import './SubmitEvent.css';

const SubmitEvent = ({ user }) => {
    const [eventDate, setEventDate] = useState(null);
    const [eventName, setEventName] = useState('');
    const [venue, setVenue] = useState('');
    const [eventDetails, setEventDetails] = useState('');
    const [is21Plus, setIs21Plus] = useState(false);
    const [ticketLink, setTicketLink] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formatUrl = (url) => {
        if (url.startsWith('http://') || url.startsWith('https://')) {
            return url;
        } else if (url.startsWith('www.')) {
            return `http://${url}`;
        } else {
            return `http://${url}`;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (eventDate && eventName && venue && eventDetails && ticketLink && termsAccepted) {
            setIsSubmitting(true);
            const formattedTicketLink = formatUrl(ticketLink);
            const eventData = {
                eventDate,
                eventName,
                venue,
                eventDetails,
                is21Plus,
                ticketLink: formattedTicketLink,
                userEmail: user.email
            };

            try {
                const response = await fetch('/api/submit-event', {  // Updated API route
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(eventData),
                });

                let data;
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    data = await response.json();
                } else {
                    data = await response.text();
                }

                if (response.ok) {
                    console.log('Success:', data);
                    setShowThankYou(true);
                    // Reset form fields
                    setEventDate(null);
                    setEventName('');
                    setVenue('');
                    setEventDetails('');
                    setIs21Plus(false);
                    setTicketLink('');
                    setTermsAccepted(false);
                } else {
                    console.error('Server error:', data);
                    alert(`Error: ${typeof data === 'string' ? data : JSON.stringify(data, null, 2)}`);
                }
            } catch (error) {
                console.error('Network error:', error);
                alert(`Network error: ${error.message}`);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    if (!user) {
        return (
            <div className="submit-event-container">
                <h2>Please Log In or Create an Account to Submit an Event</h2>
            </div>
        );
    }

    return (
        <div className="submit-event-container">
            <h2 className="submit-event-header">Submit an Event</h2>
            <form onSubmit={handleSubmit} className="submit-event-form">
                <div className="form-group">
                    <label>Event Date:</label>
                    <FaCalendarAlt className="icon" />
                    <DatePicker
                        selected={eventDate}
                        onChange={date => setEventDate(date)}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select a date"
                        required
                        className="date-input"
                        calendarClassName="custom-calendar"
                    />
                </div>
                <div className="form-group">
                    <label>Event Name:</label>
                    <FaInfoCircle className="icon" />
                    <input
                        type="text"
                        value={eventName}
                        onChange={e => setEventName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Venue:</label>
                    <FaMapMarkerAlt className="icon" />
                    <input
                        type="text"
                        value={venue}
                        onChange={e => setVenue(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group event-details">
                    <label>Event Details:</label>
                    <FaInfoCircle className="icon" />
                    <textarea
                        value={eventDetails}
                        onChange={e => setEventDetails(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group checkbox-group">
                    <input
                        type="checkbox"
                        checked={is21Plus}
                        onChange={e => setIs21Plus(e.target.checked)}
                        id="is21Plus"
                    />
                    <label htmlFor="is21Plus">21+ Event</label>
                </div>
                <div className="form-group">
                    <label>Ticket Link:</label>
                    <FaLink className="icon" />
                    <input
                        type="text"
                        value={ticketLink}
                        onChange={e => setTicketLink(e.target.value)}
                        placeholder="www.example.com or http://example.com"
                        required
                    />
                </div>
                <div className="form-group checkbox-group terms-checkbox">
                    <input
                        type="checkbox"
                        checked={termsAccepted}
                        onChange={e => setTermsAccepted(e.target.checked)}
                        id="termsAccepted"
                        required
                    />
                    <label htmlFor="termsAccepted">
                        Please note that all events must be approved before being listed on our platform. To maintain the quality and focus of our listings, we ask that you do not submit cover bands, karaoke nights, open mic events, or jam sessions, as these types of events will not be accepted. We aim to feature original and curated performances. Thank you for your understanding and cooperation!
                    </label>
                </div>
                <button 
                    type="submit" 
                    className={`submit-button ${(!termsAccepted || isSubmitting) ? 'disabled' : ''}`} 
                    disabled={!termsAccepted || isSubmitting}
                >
                    {isSubmitting ? (
                        <>
                            <FaSpinner className="fa-spin" /> Submitting, please wait...
                        </>
                    ) : (
                        <>
                            <FaSearch /> Submit Event
                        </>
                    )}
                </button>
            </form>
            {showThankYou && (
                <div className="thank-you-popup">
                    <p>Thank You For Submitting A Show</p>
                    <button onClick={() => setShowThankYou(false)}>Close</button>
                </div>
            )}
        </div>
    );
};

export default SubmitEvent;