import React, { useState, useEffect } from 'react';
import Header from './Components/Header'; // Adjust the path if necessary
import EventList from './Components/EventList'; 
import './App.css'; // Assuming you have a general CSS file for the app
import Footer from './Components/Footer';
import { SupabaseProvider, useSupabase } from './Components/SupabaseProvider.tsx';
import Login from './Components/Login'; // Add this import
import Search from './Components/Search';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './Components/About';
import ResetPassword from './Components/ResetPassword'; // Add this import
import SubmitEvent from './Components/SubmitEvent';

const AppContent = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showSearch, setShowSearch] = useState(false); // Add this line
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useState(null);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const supabase = useSupabase();

    useEffect(() => {
        if (supabase && supabase.auth) {
            const checkSession = async () => {
                try {
                    const { data: { session } } = await supabase.auth.getSession();
                    setUser(session?.user || null);
                } catch (error) {
                    console.error("Error checking session:", error);
                } finally {
                    setIsLoading(false);
                }
            };

            checkSession();

            const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
                setUser(session?.user || null);
            });

            return () => subscription.unsubscribe();
        }
    }, [supabase]);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleLogin = () => {
        if (!user) {
            setShowLogin(!showLogin);
        }
    };

    const handleLogin = (userData) => {
        setUser(userData);
        setShowLogin(false); // Close the login panel after successful login
    };

    const handleLogout = async () => {
        if (supabase && supabase.auth) {
            try {
                await supabase.auth.signOut();
                setUser(null);
                // Optionally, you can add any additional logout logic here
            } catch (error) {
                console.error("Error signing out:", error);
            }
        }
    };

    const handleSearch = (params) => {
        setSearchParams(params);
        setShowSearch(false);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="app">
            <Header 
                toggleLogin={toggleLogin} 
                isLoggedIn={!!user} 
                onLogout={handleLogout}
                toggleSearch={() => setShowSearch(!showSearch)}
                user={user} // ← Pass the user prop
            />
            <main className="app-main">
                {showLogin && !user && (
                    <Login 
                        onClose={() => setShowLogin(false)} 
                        onLogin={handleLogin}
                    />
                )}
                {showSearch && (
                    <Search 
                        onSearch={handleSearch}
                        onClose={() => setShowSearch(false)}
                        isDesktop={isDesktop}
                    />
                )}
                <Routes>
                    <Route 
                        path="/" 
                        element={<EventList user={user} searchParams={searchParams} setSearchParams={setSearchParams} />} 
                    />
                    <Route path="/about" element={<About />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/submit-event" element={<SubmitEvent user={user} />} />
                </Routes>
            </main>
            <Footer />

        </div>
    );
};

const App = () => (
    <Router>
        <SupabaseProvider>
            <AppContent />
        </SupabaseProvider>
    </Router>
);

export default App;
