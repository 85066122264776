import React from 'react';
import './PaginationControls.css';

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => (
    <div className="pagination-controls">
        <button 
            onClick={() => onPageChange(1)} 
            disabled={currentPage === 1}
        >
            First
        </button>
        <button 
            onClick={() => onPageChange(currentPage - 1)} 
            disabled={currentPage === 1}
        >
            Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button 
            onClick={() => onPageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}
        >
            Next
        </button>
        <button 
            onClick={() => onPageChange(totalPages)} 
            disabled={currentPage === totalPages}
        >
            Last
        </button>
    </div>
);

export default PaginationControls;