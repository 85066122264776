import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaTimes } from 'react-icons/fa';
import { useSupabase } from './SupabaseProvider.tsx'; // Import the useSupabase hook
import './UserPanel.css'; // Import the new UserPanel styles

const UserPanel = ({ user, onLogout, isOpen, togglePanel }) => {
    const supabase = useSupabase(); // Use the Supabase hook
    const [userStats, setUserStats] = useState({
        signUpDate: '',
        futureFavorites: 0,
        totalFavorites: 0
    });

    useEffect(() => {
        if (supabase && user) {
            console.log("Fetching user stats for user:", user.id);
            fetchUserStats();
        }
    }, [supabase, user]);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('panel-open');
        } else {
            document.body.classList.remove('panel-open');
        }

        // Cleanup function
        return () => {
            document.body.classList.remove('panel-open');
        };
    }, [isOpen]);

    const fetchUserStats = async () => {
        try {
            console.log("Starting to fetch user stats");

            // Get user's creation date from the user object
            const signUpDate = user.created_at ? new Date(user.created_at).toLocaleDateString() : 'Unknown';
            console.log("User sign-up date:", signUpDate);

            // Fetch favorites
            console.log("Fetching user favorites");
            const { data: favorites, error: favoritesError } = await supabase
                .from('saved_events')
                .select('event_id, events(date)')
                .eq('user_id', user.id);

            if (favoritesError) {
                console.error("Error fetching favorites:", favoritesError);
                throw favoritesError;
            }

            console.log("Favorites data received:", favorites);

            const now = new Date();
            const futureFavorites = favorites.filter(fav => new Date(fav.events.date) >= now).length;

            console.log("Calculated future favorites:", futureFavorites);

            setUserStats({
                signUpDate: signUpDate,
                futureFavorites: futureFavorites,
                totalFavorites: favorites.length
            });

            console.log("User stats set:", {
                signUpDate: signUpDate,
                futureFavorites: futureFavorites,
                totalFavorites: favorites.length
            });

        } catch (error) {
            console.error("Error in fetchUserStats:", error);
        }
    };

    const handleLinkClick = () => {
        togglePanel();
    };

    const handleLogout = (e) => {
        e.preventDefault();
        onLogout();
        togglePanel();
    };

    return (
        <div className={`user-panel ${isOpen ? 'open' : ''}`}>
            <div className="user-panel-content">
                <button className="close-button" onClick={togglePanel}>
                    <FaTimes />
                </button>
                <h2 className="user-panel-header">User Profile</h2>
                <p className="user-email">{user.email}</p>
                <div className="user-stats">
                    <p>User Since: {userStats.signUpDate}</p>
                    <p>Upcoming Saved Shows: {userStats.futureFavorites}</p>
                    <p>Total Shows Saved: {userStats.totalFavorites}</p>
                </div>

                <Link to="/submit-event" className="user-panel-link" onClick={handleLinkClick}>Submit Event</Link>
                <Link to="/reset-password" className="user-panel-link" onClick={handleLinkClick}>Change Password</Link>
                <a href="#" onClick={handleLogout} className="user-panel-link">Logout</a>
            </div>
        </div>
    );
};

export default UserPanel;