import React from 'react';
import EventCard from './EventCard';
import './EventListDisplay.css';

// This displays the event list
const EventListDisplay = ({ events, expandedEventId, onExpand, onSaveToggle, user, savedEventIds }) => (
    <div className="event-list">
        {events.map((event) => (
            <EventCard 
                key={event.id} 
                event={event} 
                isExpanded={event.id === expandedEventId}
                onExpand={() => onExpand(event.id)}
                onSaveToggle={onSaveToggle}
                user={user}
                isSaved={savedEventIds.includes(event.id)}
            />
        ))}
    </div>
);

export default EventListDisplay;