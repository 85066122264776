import React, { useState, useEffect } from 'react';
import { useSupabase } from './SupabaseProvider.tsx';
import EventListDisplay from './EventListDisplay';
import VenueFilter from './VenueFilter';
import PaginationControls from './PaginationControls';
import LoadingIndicator from './LoadingIndicator';
import NoEventsFound from './NoEventsFound';
import './EventList.css';

const ITEMS_PER_PAGE = 50;

const EventList = ({ user, searchParams, setSearchParams }) => {
    const supabase = useSupabase();
    const [allEvents, setAllEvents] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [selectedVenue, setSelectedVenue] = useState('all');
    const [expandedEventId, setExpandedEventId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [savedEventIds, setSavedEventIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState('');

    useEffect(() => {
        if (supabase) {
            fetchEvents();
            if (user) {
                fetchSavedEvents();
            }
        } else {
            console.log("Supabase client not available yet");
        }
    }, [supabase, user]);

    useEffect(() => {
        localStorage.setItem('selectedVenue', selectedVenue);
        if (allEvents.length > 0) {
            applyFilters();
        }
    }, [selectedVenue, allEvents]);

    useEffect(() => {
        if (allEvents.length > 0) {
            applySearchFilters();
        }
    }, [searchParams, allEvents]);

    const fetchSavedEvents = async () => {
        try {
            const { data: savedEventsData, error: savedEventsError } = await supabase
                .from('saved_events')
                .select('event_id')
                .eq('user_id', user.id);

            if (savedEventsError) throw savedEventsError;

            console.log("Saved Events:", savedEventsData);

            const savedIds = savedEventsData.map(item => item.event_id);

            // Fetch the corresponding events from the events table
            const { data: eventsData, error: eventsError } = await supabase
                .from('events')
                .select('*')
                .in('id', savedIds);

            if (eventsError) throw eventsError;

            console.log("Corresponding Events:", eventsData);

            // Update state with the fetched events
            setSavedEventIds(savedIds);
            // You might want to update another state variable with the full event data
            // setUserSavedEvents(eventsData);

        } catch (error) {
            console.error("Error fetching saved events:", error);
        }
    };

    const fetchEvents = async () => {
        setIsLoading(true);
        try {
            console.log("Fetching events from Supabase...");
            if (!supabase) {
                throw new Error("Supabase client is not initialized");
            }

            const today = new Date();
            today.setHours(0, 0, 0, 0);
            // Convert to EST
            const todayEST = new Date(today.toLocaleString("en-US", {timeZone: "America/New_York"}));
            //console.log("Today in EST:", todayEST);
            
            const { data, error } = await supabase
                .from('events')
                .select()
                .gte('date', todayEST.toISOString().split('T')[0]) // Use only the date part in EST
                .order('date', { ascending: true });
    

            if (error) {
                throw error;
            }

            if (!data) {
                throw new Error("No data received from Supabase");
            }

            console.log(`Successfully fetched ${data.length} events from the server.`);
            if (data.length > 0) {
                console.log("Sample event:", data[0]);
            } else {
                console.log("No events found in the database.");
            }

            const parsedData = data.map((item, index) => ({
                ...item,
                id: item.id || index,
                Date: new Date(item.date).toISOString(),
                'Event Name': item.event_name,
                'Event Details': item.event_details,
                Venue: item.venue,
                'Ticket Link': item.ticket_link,
                created_at: item.created_at // Make sure to include this field
            }));

            const filteredData = filterFutureEvents(parsedData);
            setAllEvents(filteredData);
            setFilteredEvents(filteredData);
            setEvents(filteredData.slice(0, ITEMS_PER_PAGE));

            console.log(`Processed ${filteredData.length} future events.`);
        } catch (error) {
            console.error("Error fetching events:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterFutureEvents = (events) => {
        const now = new Date();
        const todayEST = new Date(now.toLocaleString("en-US", {timeZone: "America/New_York"}));
        todayEST.setHours(0, 0, 0, 0);

        return events.filter(event => {
            const eventDate = new Date(event.Date);
            if (isNaN(eventDate.getTime())) {
                console.warn(`Invalid date for event: ${event.id}`);
                return false; // Filter out events with invalid dates
            }
            eventDate.setHours(0, 0, 0, 0);
            return eventDate >= todayEST;
        }).map(event => ({
            ...event,
            dateAdded: new Date(event['Date Added'] || new Date()) // Use current date if 'Date Added' is missing
        }));
    };

    const filterNewShows = (events) => {
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        sevenDaysAgo.setHours(0, 0, 0, 0); // Set to start of the day

        return events.filter(event => {
            const eventCreatedAt = new Date(event.created_at);
            
            // Check if the event was created less than 2 days ago
            return eventCreatedAt > sevenDaysAgo;
        });
    };

    const handleVenueChange = (venue) => {
        setSelectedVenue(venue);
        applyFilters(venue);
    };

    const handleEventExpand = (eventId) => {
        setExpandedEventId(eventId === expandedEventId ? null : eventId);
    };

    const totalPages = Math.ceil(filteredEvents.length / ITEMS_PER_PAGE);

    const handlePageChange = (newPage) => {
        const startIndex = (newPage - 1) * ITEMS_PER_PAGE;
        const paginatedEvents = filteredEvents.slice(startIndex, startIndex + ITEMS_PER_PAGE);
        setEvents(paginatedEvents);
        setCurrentPage(newPage);
        setExpandedEventId(null);
        window.scrollTo(0, 0);
    };

    const handleSaveToggle = async (eventId, isChecked) => {
        if (!user) return;

        try {
            if (isChecked) {
                await supabase
                    .from('saved_events')
                    .insert({ user_id: user.id, event_id: eventId });
                setSavedEventIds(prev => [...prev, eventId]);
            } else {
                await supabase
                    .from('saved_events')
                    .delete()
                    .match({ user_id: user.id, event_id: eventId });
                setSavedEventIds(prev => prev.filter(id => id !== eventId));
            }

            // Update the events state without changing the page
            setEvents(prevEvents => 
                prevEvents.map(event => 
                    event.id === eventId 
                        ? { ...event, isSaved: isChecked } 
                        : event
                )
            );

            // Update filteredEvents if necessary
            setFilteredEvents(prevFiltered => 
                prevFiltered.map(event => 
                    event.id === eventId 
                        ? { ...event, isSaved: isChecked } 
                        : event
                )
            );

        } catch (error) {
            console.error("Error toggling saved event:", error);
        }
    };

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue === 'saved' && !user) {
            // If 'Saved Events' is selected but user is not logged in, don't do anything
            return;
        }
        // Handle the selection change
        // ...
    };

    const handleClearSearch = () => {
        setSearchParams(null);
        setSelectedVenue('all'); // Reset dropdown to 'All Shows'
        setFilteredEvents(allEvents);
        setEvents(allEvents.slice(0, ITEMS_PER_PAGE));
        setCurrentPage(1);
    };

    const isSearchActive = searchParams && Object.values(searchParams).some(value => value !== null && value !== '');

    const applySearchFilters = () => {
        let filteredData = allEvents;

        if (searchParams) {
            filteredData = allEvents.filter(event => {
                const eventDate = new Date(event.Date);
                eventDate.setHours(0, 0, 0, 0);

                const matchesText = searchParams.text ? 
                    ((event['Event Name']?.toLowerCase() || '').includes(searchParams.text.toLowerCase()) ||
                    (event['Event Details']?.toLowerCase() || '').includes(searchParams.text.toLowerCase())) : 
                    true;

                let matchesStartDate = true;
                if (searchParams.startDate) {
                    const startDate = new Date(searchParams.startDate);
                    startDate.setHours(0, 0, 0, 0);
                    matchesStartDate = eventDate >= startDate;
                }

                let matchesEndDate = true;
                if (searchParams.endDate) {
                    const endDate = new Date(searchParams.endDate);
                    endDate.setHours(23, 59, 59, 999);
                    matchesEndDate = eventDate <= endDate;
                }

                let matchesVenue = true;
                if (searchParams.venue && searchParams.venue !== 'all') {
                    matchesVenue = event.Venue === searchParams.venue;
                }
                
                return matchesText && matchesStartDate && matchesEndDate && matchesVenue;
            });
        }

        setFilteredEvents(filteredData);
        setEvents(filteredData.slice(0, ITEMS_PER_PAGE));
        setCurrentPage(1);
    };

    const applyFilters = (venue = selectedVenue) => {
        let newFilteredEvents;
        if (venue === 'all') {
            newFilteredEvents = allEvents;
        } else if (venue === 'new') {
            newFilteredEvents = filterNewShows(allEvents);
        } else if (venue === 'saved') {
            newFilteredEvents = allEvents.filter(event => savedEventIds.includes(event.id));
        } else {
            newFilteredEvents = allEvents.filter(item => item.Venue === venue);
        }
        setFilteredEvents(newFilteredEvents);
        setEvents(newFilteredEvents.slice(0, ITEMS_PER_PAGE));
        setCurrentPage(1);
    };

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    return (
        <div className="event-list-container">
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <>
                    <VenueFilter 
                        onVenueChange={handleVenueChange}
                        isSearchActive={isSearchActive}
                        onClearSearch={handleClearSearch}
                        user={user}
                    />
                    <PaginationControls 
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                    <EventListDisplay 
                        events={events}
                        expandedEventId={expandedEventId}
                        onExpand={handleEventExpand}
                        onSaveToggle={handleSaveToggle}
                        user={user}
                        savedEventIds={savedEventIds}
                    />
                    <PaginationControls 
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                    {filteredEvents.length === 0 && <NoEventsFound />}
                </>
            )}
        </div>
    );
};

export default EventList;
