import React from 'react';
import { FaStar, FaRegStar } from 'react-icons/fa';

const SaveEvent = ({ eventId, onToggle, user, isSaved }) => {
  const handleToggle = () => {
    if (!user) return; // Don't do anything if no user is logged in
    onToggle(!isSaved);
  };

  const starStyle = {
    cursor: user ? 'pointer' : 'default',
    fontSize: '24px',
  };

  const renderStar = () => {
    if (!user) {
      // Dim grey star when no user is logged in
      return <FaStar style={{ ...starStyle, color: '#d3d3d3' }} />;
    } else if (isSaved) {
      // Filled yellow star when saved
      return <FaStar style={{ ...starStyle, color: '#FFD700' }} />;
    } else {
      // Outlined star with white fill when not saved
      return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <FaRegStar style={{ ...starStyle, color: '#C0C0C0' }} />
          <FaStar 
            style={{ 
              ...starStyle, 
              position: 'absolute', 
              left: 0, 
              color: 'white', 
              fontSize: '20px',
              top: '2px',
              left: '2px',
            }} 
          />
        </div>
      );
    }
  };

  return (
    <div className="save-event-star" onClick={handleToggle}>
      {renderStar()}
    </div>
  );
};

export default SaveEvent;
