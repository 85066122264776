import React, { useState, useEffect } from 'react';
import { useSupabase } from './SupabaseProvider.tsx';
import './VenueFilter.css';

const VenueFilter = ({ onVenueChange, isSearchActive, onClearSearch, user, selectedVenue }) => {
    const supabase = useSupabase();
    const [venues, setVenues] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchVenues();
    }, [supabase]);

    useEffect(() => {
        localStorage.setItem('selectedVenue', selectedVenue);
    }, [selectedVenue]);

    const fetchVenues = async () => {
        try {
            const { data, error, status } = await supabase
                .from('events')
                .select('venue')
                .not('venue', 'is', null);

            if (error) throw error;

            if (status !== 200) {
                throw new Error(`Unexpected status code: ${status}`);
            }

            if (!Array.isArray(data)) {
                console.error("Unexpected data format:", data);
                throw new Error("Data is not an array");
            }

            const uniqueVenues = [...new Set(data.map(item => item.venue))];
            setVenues(uniqueVenues.sort((a, b) => a.localeCompare(b)));
            setError(null); // Clear any previous errors if successful
        } catch (error) {
            console.error("Error fetching venues:", error);
            setError("Unable to load venues. Please try again later.");
            setVenues([]);
        }
    };

    const handleVenueChange = (e) => {
        const venue = e.target.value;
        if (venue === 'saved' && !user) {
            return;
        }
        onVenueChange(venue);
    };

    return (
        <div className="venue-filter">
            {error && <div className="error-message">{error}</div>}
            <select id="venueFilter" value={selectedVenue} onChange={handleVenueChange}>
                <option value="all">All Shows</option>
                <option value="new">Just Added!</option>
                <option 
                    value="saved" 
                    disabled={!user}
                    style={{ color: user ? 'black' : 'grey' }}
                >
                    {user ? 'Saved Events' : 'Log In For Saved Events'}
                </option>
                <option disabled>──────────</option>
                {venues.map(venue => (
                    <option key={venue} value={venue}>{venue}</option>
                ))}
            </select>
            {isSearchActive && (
                <button onClick={onClearSearch} className="clear-search-button">
                    Clear Search
                </button>
            )}
        </div>
    );
};

export default VenueFilter;