import React from 'react';
import SaveEvent from './SaveEvent';
import CardButton from './CardButton';
import './EventCard.css';

const EventCard = ({ event, isExpanded, onExpand, onSaveToggle, user, isSaved }) => {
    const handleCardClick = () => {
        onExpand(event.id);
    };

    const handleSaveClick = (e) => {
        e.stopPropagation();
    };

    const addToGoogleCalendar = () => {
        const eventDate = new Date(event.Date);
        const endDate = new Date(eventDate.getTime() + 2 * 60 * 60 * 1000); // Assume 2 hours duration
        const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event['Event Name'])}&dates=${eventDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}/${endDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}&details=${encodeURIComponent(event['Event Details'])}&location=${encodeURIComponent(event.Venue)}`;
        window.open(googleCalendarUrl, '_blank');
    };

    const addToICalOutlook = () => {
        const eventDate = new Date(event.Date);
        const endDate = new Date(eventDate.getTime() + 2 * 60 * 60 * 1000); // Assume 2 hours duration
        const iCalContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${eventDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}
DTEND:${endDate.toISOString().replace(/-|:|\.\d\d\d/g, '')}
SUMMARY:${event['Event Name']}
DESCRIPTION:${event['Event Details']}
LOCATION:${event.Venue}
END:VEVENT
END:VCALENDAR`;
        const blob = new Blob([iCalContent], { type: 'text/calendar;charset=utf-8' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${event['Event Name']}.ics`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const eventDate = new Date(event.Date);
    const formattedDate = eventDate.toLocaleDateString('en-US', { 
        month: '2-digit', 
        day: '2-digit', 
        year: '2-digit'
    }).replace(/\//g, '.');
    const dayOfWeek = eventDate.toLocaleDateString('en-US', { weekday: 'short' });

    return (
        <div className={`event-card ${isExpanded ? 'expanded' : ''}`} onClick={handleCardClick}>
            <div className="event-header">
                <div className="event-date-container">
                    <span className="event-date">{formattedDate}</span>
                    <span className="event-day">{dayOfWeek}</span>
                </div>
                <div className="event-info">
                    <h3 className="event-name">{event['Event Name']}</h3>
                    <p className="event-venue">{event.Venue}</p>
                </div>
                <div className="save-event-container" onClick={handleSaveClick}>
                    <SaveEvent 
                        eventId={event.id} 
                        onToggle={(newState) => onSaveToggle(event.id, newState)} 
                        user={user} 
                        isSaved={isSaved} 
                    />
                </div>
            </div>
            {isExpanded && (
                <div className="expanded-content" onClick={(e) => e.stopPropagation()}>
                    <div className="event-details">
                        <p>{event['Event Details']}</p>
                    </div>
                    <div className="event-buttons">
                        <CardButton onClick={addToGoogleCalendar}>
                            Add to Google Calendar
                        </CardButton>
                        <CardButton onClick={addToICalOutlook}>
                            Add to iCal/Outlook
                        </CardButton>
                        {event['Ticket Link'] && (
                            <CardButton href={event['Ticket Link']}>
                                Buy Tickets
                            </CardButton>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventCard;
