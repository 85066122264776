import React, { useState } from 'react';
import { useSupabase } from './SupabaseProvider.tsx';
import { useNavigate } from 'react-router-dom';
import './ResetPassword.css';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const supabase = useSupabase();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        try {
            const { error } = await supabase.auth.updateUser({ password: password });
            if (error) throw error;
            setMessage('Password updated successfully');
            setTimeout(() => navigate('/'), 2000); // Redirect to home page after 2 seconds
        } catch (error) {
            setMessage(error.message);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="reset-password-container">
            <h2 className="reset-password-heading">Reset Your Password</h2>
            <form onSubmit={handleSubmit} className="reset-password-form">
                <div className="reset-password-input-group">
                    <label htmlFor="password" className="reset-password-label">New Password:</label>
                    <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="reset-password-input"
                    />
                </div>
                <div className="reset-password-input-group">
                    <label htmlFor="confirmPassword" className="reset-password-label">Confirm New Password:</label>
                    <input
                        type={showPassword ? "text" : "password"}
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="reset-password-input"
                    />
                </div>
                <div className="reset-password-checkbox-group">
                    <input
                        type="checkbox"
                        id="showPassword"
                        checked={showPassword}
                        onChange={toggleShowPassword}
                        className="reset-password-checkbox"
                    />
                    <label htmlFor="showPassword" className="reset-password-label">Show Password</label>
                </div>
                <button type="submit" className="reset-password-button">Reset Password</button>
            </form>
            {message && <p className="reset-password-message">{message}</p>}
        </div>
    );
};

export default ResetPassword;
