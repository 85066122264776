import React, { useState, useEffect, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaSearch, FaCalendarAlt, FaTimes } from 'react-icons/fa';
import VenueFilter from './VenueFilter';
import './Search.css';

const Search = ({ onSearch, onClose, isDesktop, user }) => {
    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedVenue, setSelectedVenue] = useState('all');

    useEffect(() => {
        document.body.classList.add('search-open');
        // Reset the venue filter to 'all' when the search component is opened
        setSelectedVenue('all');
        return () => {
            document.body.classList.remove('search-open');
        };
    }, []);

    const handleSearch = () => {
        onSearch({
            text: searchText,
            startDate,
            endDate,
            venue: selectedVenue
        });
        onClose();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (isDesktop) {
                e.preventDefault();
                handleSearch();
            } else {
                e.preventDefault();
                e.target.blur();
            }
        }
    };

    const handleVenueChange = (venue) => {
        setSelectedVenue(venue);
    };

    // Custom input component for DatePicker
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="date-input-wrapper" onClick={onClick}>
            <FaCalendarAlt className="calendar-icon" />
            <input
                value={value}
                readOnly
                className="date-input"
                placeholder="Select date"
                ref={ref}
            />
        </div>
    ));

    return (
        <div className={`search-container ${isDesktop ? 'desktop' : ''}`}>
            <div className="search-popup">
                <button className="close-button" onClick={onClose}>
                    <FaTimes />
                </button>
                <h2 className="search-header">Advanced Search</h2>
                <div className="search-input-container">
                    <FaSearch className="search-icon" />
                    <input
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="Search event titles and details"
                        className="search-input"
                        enterKeyHint="done"
                    />
                </div>
                
                <div className="date-pickers">
                    <div className="date-picker">
                        <DatePicker
                            selected={startDate}
                            onChange={setStartDate}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="Start Date"
                            customInput={<CustomInput />}
                        />
                    </div>
                    <div className="date-picker">
                        <DatePicker
                            selected={endDate}
                            onChange={setEndDate}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            placeholderText="End Date"
                            customInput={<CustomInput />}
                        />
                    </div>
                </div>
                {/* <VenueFilter       // THIS ISN'T WORKING TODO:
                    onVenueChange={handleVenueChange}
                    isSearchActive={false}
                    onClearSearch={() => {}}
                    user={user}
                    selectedVenue={selectedVenue}
                /> */}
                <button onClick={handleSearch} className="search-button">
                    <FaSearch /> Search
                </button>
            </div>
        </div>
    );
};

export default Search;
