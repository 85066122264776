import React, { useState, useEffect } from 'react';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const contentWidth = 1200;
  const isDesktop = windowWidth >= contentWidth;

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <footer className={`footer ${isDesktop ? 'desktop' : 'mobile'}`}>
      <div className="footer-content">
        <div className="footer-logo">
          <img src={require('../assets/lllogo.svg').default} alt="Stage Dive Philly Logo" className="footer-logo-img" />
        </div>
        <div className="social-links">
          {/* <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebookF />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a> */}
          {/* <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
          </a> */}
        </div>
        <div className="footer-links">
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/submit-event">Submit Event</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
